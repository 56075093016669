const surveyEn = [
  {
    index: 1,
    key: 'size',
    type: 'choices',
    tooltip: false,
    question: 'What kind of car are you looking for ?',
    choices: [
      {
        choice: 'Small',
        value: 1,
      },
      {
        choice: 'Medium',
        value: 2,
      },
      {
        choice: 'Large',
        value: 3,
      },
    ],
  },
  {
    index: 2,
    key: 'distance',
    type: 'choices',
    question: 'How many kilometers do drive per day on average ?',
    tooltip: false,
    choices: [
      {
        choice: 'Less than 50 km/day',
        value: 49,
      },
      {
        choice: '50 - 180 km/day',
        value: 125,
      },
      {
        choice: '180 - 280 km/day',
        value: 230,
      },
      {
        choice: '280 - 400 km/day',
        value: 340,
      },
      {
        choice: 'More than 400 km/day',
        value: 401,
      },
    ],
  },
  {
    index: 3,
    key: 'trips',
    type: 'number',
    question: 'How many car trip do you do on average in a day ?',
    metric: 'Travels/day',
    placeholder: 'E.g. 4',
    tooltip: true,
    info: 'Count the small trips as well',
  },
  {
    index: 4,
    key: 'charge',
    type: 'choices',
    question: 'Would you be able to charge an electric car at home, now or in the near future?',
    tooltip: true,
    info: "Consider installing a charging station at your home.",
    choices: [
      {
        choice: 'Yes',
        value: 1,
      },
      {
        choice: 'No',
        value: 0,
      },
    ],
  },
  {
    index: 5,
    key: 'station',
    type: 'choices',
    tooltip: true,
    info: 'Can you install a charging station at home now or in the near future and/or can you charge at work?',
    question:
      'Can you now or in the near future recharge at work or on the road?',
    choices: [
      {
        choice: 'Yes',
        value: 1,
      },
      {
        choice: 'No',
        value: 0,
      },
    ],
  },
];

const surveyFr = [
  {
    index: 1,
    key: 'size',
    type: 'choices',
    tooltip: false,
    question: 'Quelle serait la taille de votre prochaine voiture ?',
    choices: [
      {
        choice: 'Petite',
        value: 1,
      },
      {
        choice: 'Moyenne',
        value: 2,
      },
      {
        choice: 'Grande',
        value: 3,
      },
    ],
  },
  {
    index: 2,
    key: 'distance',
    type: 'choices',
    tooltip: false,
    question:
      'Combien de kilomètres parcourez-vous en moyenne chaque jour en voiture ?',
    choices: [
      {
        choice: 'Moins de 50 km/jour',
        value: 49,
      },
      {
        choice: '50 - 180 km/jour',
        value: 125,
      },
      {
        choice: '180 - 280 km/jour',
        value: 230,
      },
      {
        choice: '280 - 400 km/jour',
        value: 340,
      },
      {
        choice: 'Plus de 400 km/jour',
        value: 401,
      },
    ],
  },
  {
    index: 3,
    key: 'trips',
    type: 'number',
    tooltip: true,
    question:
      'Combien de trajets en voiture effectuez-vous en moyenne quotidiennement ?',
    metric: 'Trajets/jour',
    placeholder: 'Ex: 4',
    info: 'Comptez également les petits trajets',
  },
  {
    index: 4,
    key: 'charge',
    type: 'choices',
    tooltip: true,
    question: 'Auriez-vous la possibilité de recharger une voiture électrique chez vous, maintenant ou dans un avenir proche?',
    info: "Réfléchissez à la possibilité d'installer une station de recharge à votre domicile.",
    choices: [
      {
        choice: 'Oui',
        value: 1,
      },
      {
        choice: 'Non',
        value: 0,
      },
    ],
  },
  {
    index: 5,
    key: 'station',
    type: 'choices',
    tooltip: true,
    info: 'Pouvez-vous installer une station de recharge à la maison maintenant ou dans un avenir proche et/ou pouvez-vous recharger au travail?',
    question:
      'Pouvez-vous maintenant ou dans un avenir proche recharger au travail ou sur la route?',
    choices: [
      {
        choice: 'Oui',
        value: 1,
      },
      {
        choice: 'Non',
        value: 0,
      },
    ],
  },
];

export { surveyEn, surveyFr };
