<template>
  <div class="row compare-engines content-row">
    <div class="col-12 col-lg-6">
      <el-select
        v-model="selected"
        :placeholder="$t('nav.select')"
        @change="getSeries"
      >
        <el-option
          v-for="choice in choices"
          :key="choice.id"
          :label="$t(`select.${choice.id}`)"
          :value="choice.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-12 col-lg-6">
      <el-select
        v-model="selected2"
        :placeholder="$t('nav.select')"
        @change="getSeries"
      >
        <el-option
          v-for="choice in choices"
          :key="choice.id"
          :label="$t(`select.${choice.id}`)"
          :value="choice.id"
        >
        </el-option>
      </el-select>
    </div>
    <div
      class="col-12 description-col"
      v-if="selected && selected2"
    >
      <p><i class="bi bi-info-square-fill"></i>{{ $t('indicators.percentage') }}</p>
    </div>
  </div>
  <div class="row content-row" v-if="selected && selected2">
    <div class="col-12 mb-4 chart-col">
      <apexchart
        :height="chartHeight"
        :width="chartWidth"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    <CompareTable :choice1="selected" :choice2="selected2" />
  </div>
</template>

<script>
import envScores from '@/utils/data/envScores';
import CompareTable from '@/components/compare/CompareTable';
import getChartWidth from '@/utils/helpers/getChartWidth';

export default {
  name: 'CompareEngines',
  components: {
    CompareTable,
  },
  data() {
    return {
      selected: 'bev-small',
      selected2: 'bev-medium',
      chartWidth: null,
      chartHeight: null,
      serie1: {},
      serie2: {},
      series: [],
      choices: [],
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [
            'Climate',
            'Particulate',
            'Fossils',
            'Materials/Minerals',
            'Single score',
          ],
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
        },
        colors: ['#35c6f4', '#419183']
      },
    };
  },
  created() {
    this.chartWidth = getChartWidth(450, 550, 800);
    this.chartHeight = getChartWidth(350, 400, 450);
    let choices = [];
    for (let i in envScores) {
      const envScore = envScores[i];
      choices.push({
        id: envScore.id,
        name: envScore.chart,
      });
    }
    this.choices = choices;
    this.getSeries();
  },
  methods: {
    getSeries() {
      const engine1 = envScores.find((engine) => engine.id === this.selected);
      const engines2 = envScores.find((engine) => engine.id === this.selected2);

      let serie1 = [];
      for (let i in engine1.indicators) {
        const indicator = engine1.indicators[i];
        const score = 100 - indicator.score;
        serie1.push(score);
      }

      let serie2 = [];
      for (let i in engines2.indicators) {
        const indicator = engines2.indicators[i];
        const score = 100 - indicator.score;
        serie2.push(score);
      }

      this.series = [
        {
          name: engine1.chart,
          data: serie1,
        },
        {
          name: engines2.chart,
          data: serie2,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.compare-engines {
  .col-12.col-lg-6 {
    display: flex;
    justify-content: center;
    .el-select {
      width: 450px;
    }
  }
}

@include bp-down(lg) {
  .compare-engines {
    .col-12.col-lg-6 {
      margin-bottom: 1rem;
    }
  }
}
</style>
