<template>
  <div class="row content-row">
    <div class="col-12 description-col">
      <p>
        <i class="bi bi-info-square-fill"></i
        >{{ $t('indicators.global_score') }}
      </p>
    </div>
    <div class="col-12 chart-col">
      <apexchart
        :width="chartWidth"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    <GlobalLegend />
  </div>
</template>

<script>
import GlobalLegend from '@/components/compare/GlobalLegend';
import envScores from '@/utils/data/envScores';
import getChartWidth from '@/utils/helpers/getChartWidth';

export default {
  name: 'GlobalChart',
  components: {
    GlobalLegend,
  },
  data() {
    return {
      chartWidth: null,
      options: {
        chart: {
          fontFamily: 'Fira Sans',
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: 'Final Score',
        },
        xaxis: {
          categories: [],
          max: 100,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
          },
        },
        colors: [
          '#2aa10f',
          '#2aa10f',
          '#92e000',
          '#92e000',
          '#ffd500',
          '#ffd500',
          '#f58b00',
          '#f58b00',
          '#f58b00',
          '#de3700',
          '#de3700',
        ],
      },
      series: [
        {
          name: 'Final score',
          data: [],
        },
      ],
    };
  },
  created() {
    this.chartWidth = getChartWidth(350, 600, 800);
    let data = [];
    let categories = [];
    for (let i in envScores) {
      const envScore = envScores[i];
      data.push(envScore.score);
      categories.push(envScore.chart);
    }
    this.series[0].data = data;
    this.options.xaxis.categories = categories;
  },
};
</script>
