export default class Options {
  static getOptions(answers) {
    let bev;
    let phev;
    let filteredOptions;

    const options = ['bev', 'phev', 'fcev', 'hev', 'cng', 'petrol', 'diesel'];

    phev = this.getPhev(answers);

    // Get final options depending on BEV
    if (answers.charge === 1) bev = this.getBev(answers);
    else bev = null;

    if (!bev || answers.charge === 0)
      filteredOptions = options.filter((option) => option !== 'bev');
    else filteredOptions = options;

    return {
      filteredOptions,
      bev,
      phev,
    };
  }
  
  /**
   * Check if BEV is an option
   */
  static getBev(answers) {
    const tripDistance = answers.distance / answers.trips;

    switch (answers.size) {
      case 1:
        if (answers.distance > 220) return null;
        if (tripDistance < 120) return 'small';
        if (120 <= tripDistance <= 220)
          return answers.station === 1 ? 'small' : 'medium';
        return answers.station === 1 ? 'small' : null;

      case 2:
        if (answers.distance > 320) return null;
        if (tripDistance < 120) return 'small';
        if (120 <= tripDistance <= 220)
          return answers.station === 1 ? 'small' : 'medium';
        return answers.station === 1 ? 'medium' : 'large';

      case 3:
        if (answers.distance > 320) return null;
        if (tripDistance < 220) return 'medium';
        if (220 <= tripDistance <= 320)
          return answers.station === 1 ? 'medium' : 'large';
        return answers.station === 1 ? 'medium' : null;

      default:
        return null;
    }
  }

  /**
   *  Get PHEV
   */
  static getPhev(answers) {
    const tripDistance = answers.distance / answers.trips;
    if (tripDistance < 50) {
      if (answers.distance < 50) return 'high';
      else if (50 <= answers.distance <= 100)
        return answers.station === 1 ? 'high' : 'medium';
      return answers.station === 1 ? 'high' : 'low';
    }

    if (50 <= tripDistance <= 100) {
      if (50 <= answers.distance <= 100) return 'medium';
      return answers.station === 1 ? 'high' : 'low';
    }

    if (tripDistance > 100) {
      return 'low';
    }
  }
}
