<template>
  <div class="row">
    <div class="col-10 legend-col">
      <table class="table">
        <thead>
          <tr>
            <td>
              <h4>{{ $t('indicators.legend') }}</h4>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in legend" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ $t(`select.${item.id}`) }}</td>
            <td class="text-link">
              <a href="#" @click="seeProfile(item.id)">{{
                $t('compare.see_profile')
              }}</a>
            </td>
            <td class="icon-link">
              <a href="#" @click="seeProfile(item.id)"
                ><i class="bi bi-eye-fill"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <el-dialog v-model="showModal" destroy-on-close>
    <template #title>
      <h4 class="text-primary">{{ $t(`select.${selected}`) }}</h4>
    </template>
    <el-scrollbar height="600px">
      <EngineDetails :id="selected"
    /></el-scrollbar>
  </el-dialog>
</template>

<script>
import EngineDetails from '@/components/explore/EngineDetails';
export default {
  name: 'ChartLegend',
  components: {
    EngineDetails,
  },
  data() {
    return {
      showModal: false,
      selected: '',
      legend: [
        {
          name: 'BEV (small)',
          id: 'bev-small',
        },
        {
          name: 'BEV (medium)',
          id: 'bev-medium',
        },
        {
          name: 'BEV (large)',
          id: 'bev-large',
        },
        {
          name: 'CNG',
          id: 'cng',
        },
        {
          name: 'FCEV',
          id: 'fcev',
        },
        {
          name: 'PHEV (high)',
          id: 'phev-high',
        },
        {
          name: 'PHEV (medium)',
          id: 'phev-medium',
        },
        {
          name: 'PHEV (low)',
          id: 'phev-low',
        },
        {
          name: 'HEV',
          id: 'hev',
        },
        {
          name: 'Diesel',
          id: 'diesel',
        },
        {
          name: 'Petrol',
          id: 'petrol',
        },
      ],
    };
  },
  methods: {
    seeProfile(id) {
      this.selected = id;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-col {
  margin: 0 auto;
  max-width: fit-content;
  h4 {
    font-size: 16px;
  }
  tbody {
    font-size: 14px;
    a {
      text-decoration: none;
      margin-left: 2rem;
      &:hover {
        color: $secondary;
      }
    }
    .icon-link {
      display: none;
    }
  }
}

@include bp-down(md) {
  .legend-col {
    tbody {
      tr,
      td {
        font-size: 12px;
      }
      .icon-link {
        display: inline-block;
        border: none;
      }
      .text-link {
        display: none;
      }
      a {
        margin-left: 0;
      }
    }
  }
}
</style>
