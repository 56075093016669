<template>
  <div class="container-fluid container-bg container-fh">
    <div class="row">
      <div class="col-12 mt-4 survey-col shadow">
        <div class="progress-container mb-4">
          <el-progress
            type="circle"
            :percentage="progress"
            color="#35c6f4"
            :width="70"
          />
        </div>
        <div class="survey-container" v-for="item in survey" :key="item.index">
          <SurveyItem
            :item="item"
            v-if="current === item.index"
            @next="getNext"
            @previous="getPrevious"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Scores from '@/utils/logic/Scores';
import { surveyEn, surveyFr } from '@/utils/data/survey';
import SurveyItem from '@/components/evaluate/SurveyItem';

export default {
  name: 'FullSurvey',
  components: {
    SurveyItem,
  },
  data() {
    return {
      answers: [],
      current: 1,
      progress: 0,
      userId: '',
      survey: [],
      lang: '',
      skipQ5: false,
    };
  },
  created() {
    this.lang = this.$i18n.locale;
    this.userId = localStorage.getItem('userId');
    if (this.lang === 'en') this.survey = surveyEn;
    else if (this.lang === 'fr') this.survey = surveyFr;
    else this.survey = surveyEn;
  },
  methods: {
    handleSubmit() {
      let answers = {};
      for (let i in this.answers) {
        answers[this.answers[i].key] = this.answers[i].value;
      }
      Scores.getResults(answers);
      this.$router.push({ name: 'Results', params: { id: this.userId } });
    },
    getNext(value, key) {
      this.progress = Math.round((this.current / this.survey.length) * 100);

      const answer = {
        index: this.current,
        value,
        key,
      };

      if (this.current === this.survey.length) {
        this.answers.push(answer);
        this.handleSubmit();
        return;
      }
      this.answers.push(answer);
      if (key === 'charge' && value === 0) {
        this.answers.push({
          index: this.current + 1,
          value: 0,
          key: 'station',
        });
        this.handleSubmit();
      } else {
        this.current += 1;
      }
    },
    getPrevious(data) {
      this.current = data - 1;
      const progress = this.current - 1;
      if (this.current === 1) {
        return;
      }
      this.answers = this.answers.filter((item) => item.index !== this.current);
      this.progress = Math.round((progress / this.survey.length) * 100);
    },
  },
};
</script>

<style lang="scss">
.survey-container {
  margin-top: 1rem;
}
.desktop-img {
  display: none;
}

.progress-container {
  margin: 0 auto;
  max-width: 90%;
  display: flex;
  justify-content: flex-end;
  padding: 0rem 2rem;
}

.survey-col {
  margin: 0 auto;
  background-color: $light;
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-width: 60%;
}

@include bp-down(lg) {
  .survey-col {
    max-width: 70%;
  }
}

@include bp-down(md) {
  .survey-col {
    max-width: 90%;
  }
}
</style>
