<template>
  <div class="col-12 table-responsive mt-4">
    <table class="table">
      <thead>
        <tr>
          <th class="indicator">{{ $t('indicators.indicators') }}</th>
          <th class="engine">{{ $t(`select.${engine1.id}`) }}</th>
          <th class="engine">{{ $t(`select.${engine2.id}`) }}</th>
          <th class="unit">{{ $t('indicators.units') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t('indicators.climate.title') }}</td>
          <td>{{ engine1.indicators[0].impact }}</td>
          <td>{{ engine2.indicators[0].impact }}</td>
          <td>{{ $t('indicators.climate.unit') }}</td>
        </tr>
        <tr>
          <td>{{ $t('indicators.respiratory.title') }}</td>
          <td>{{ engine1.indicators[1].impact }}</td>
          <td>{{ engine2.indicators[1].impact }}</td>
          <td>{{ $t('indicators.respiratory.unit') }}</td>
        </tr>
        <tr>
          <td>{{ $t('indicators.carriers.title') }}</td>
          <td>{{ engine1.indicators[2].impact }}</td>
          <td>{{ engine2.indicators[2].impact }}</td>
          <td>{{ $t('indicators.carriers.unit') }}</td>
        </tr>
        <tr>
          <td>{{ $t('indicators.materials.title') }}</td>
          <td>{{ engine1.indicators[3].impact }}</td>
          <td>{{ engine2.indicators[3].impact }}</td>
          <td>{{ $t('indicators.materials.unit') }}</td>
        </tr>
        <tr>
          <td>{{ $t('indicators.single.title') }}</td>
          <td>{{ engine1.indicators[4].impact }}</td>
          <td>{{ engine2.indicators[4].impact }}</td>
          <td>{{ $t('indicators.single.unit') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import envScores from '@/utils/data/envScores';

export default {
  props: ['choice1', 'choice2'],
  data() {
    return {
      engine1: {},
      engine2: {},
    };
  },
  created() {
    this.getData();
  },
  updated() {
    this.getData();
  },
  methods: {
    getData() {
      const engine1 = envScores.find((engine) => engine.id === this.choice1);
      const engine2 = envScores.find((engine) => engine.id === this.choice2);
      this.engine1 = engine1;
      this.engine2 = engine2;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  .title {
    width: 30%;
  }
  .engine {
    width: 25%;
  }
  .unit {
    width: 20%;
  }
  tr {
    td,
    th {
      border: 1px solid $light-grey;
    }
  }
}

@include bp-down(md) {
  .table {
    max-width: 95%;
    font-size: 14px;
  }
}
</style>
