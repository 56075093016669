<template>
  <div class="survey-item">
    <!-- Question -->
    <div class="card-title">
      <h3>{{ item.question }}<app-tooltip v-if="item.tooltip" :info="item.info" /></h3>
    </div>
    <!-- Question type : Choices -->
    <div v-if="item.type === 'choices'">
      <div v-for="choice in item.choices" :key="choice">
        <ChoiceItem
          :choice="choice"
          @chosen="setChoice(choice.value)"
          @isActive="activated = choice.value"
          :activated="activated === choice.value"
        />
      </div>
    </div>
    <!-- Question type : Number -->
    <div class="input-number" v-if="item.type === 'number'">
      <el-input-number
        v-model="numberChoice"
        :min="0"
        :max="50"
        :step="1"
        :placeholder="item.placeholder"
        :label="item.metric"
        @change="handleNumberChange"
      />
    </div>
    <!-- Survey Navigation -->
    <div class="survey-nav">
      <p @click="previous(item.index)" v-show="item.index !== 1">
        {{ $t('evaluate.previous') }}
      </p>
      <div></div>
      <app-button @clicked="nextQuestion" :disabled="disabled">{{
        $t('evaluate.next')
      }}</app-button>
    </div>
  </div>
</template>

<script>
import ChoiceItem from '@/components/evaluate/ChoiceItem';

export default {
  name: 'SurveyItem',
  components: {
    ChoiceItem,
  },
  props: ['item', 'type'],
  emits: ['next', 'previous'],
  data() {
    return {
      activated: null,
      choice: null,
      numberChoice: 0,
      disabled: true,
    };
  },
  methods: {
    setChoice(value) {
      this.choice = value;
      this.disabled = false;
    },
    nextQuestion() {
      this.$emit('next', this.choice, this.item.key);
    },
    previous(data) {
      this.choice = '';
      this.$emit('previous', data);
    },
    handleNumberChange() {
      if (this.numberChoice) {
        this.numberChoice = Math.round(this.numberChoice);
        this.disabled = false;
        this.choice = this.numberChoice;
      } else {
        this.disabled = true;
      }
    },
    handleChange() {
      if (this.choice) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
};
</script>

<style lang="scss">
.survey-item {
  max-width: 85%;
  margin: 0 auto;
  padding: 0rem 2rem;
  .card-title {
    text-align: center;
    font-family: $accent-font;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 2rem;
    i {
      margin-left: 0.5rem;
      cursor: pointer;
      font-size: 1.25rem;
    }
  }
  .card {
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    max-width: 20rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
    border-radius: 0;
    .card-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      color: $dark;
    }
    &:hover,
    &:focus,
    &.card-active {
      background-color: $light-bg;
      font-weight: 600;
      border-color: $primary;
    }
  }
  .survey-nav {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    p {
      color: $secondary;
      text-decoration: underline;
      text-underline-offset: 5px;
      cursor: pointer;
    }
  }
  .input-number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem!important;
  }
}

@include bp-down(md) {
  .survey-item {
    .card-title {
      font-size: 1.2rem;
    }
    .card-content {
      font-size: 1rem;
    }
    .survey-nav {
      margin-top: 2rem;
    }
  }
}
</style>
