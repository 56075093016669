import Options from './Options';
import envScores from '@/utils/data/envScores';
import _ from 'lodash';

export default class Scores {
  static getResults(answers) {
    const { filteredOptions, bev, phev } = Options.getOptions(answers);
    const results = this.getScores(filteredOptions, bev, phev);
    this.setResults(results);
  }

  /**
   * Get top 3 scores
   */
  static getScores(options, bev, phev) {
    let results = [];

    for (let i in options) {
      if (options[i] === 'bev') {
        const bevRes = envScores.find((score) => score.battery === bev);
        results.push(bevRes);
      } else if (options[i] === 'phev') {
        const phevRes = envScores.find((score) => score.energy === phev);
        results.push(phevRes);
      } else {
        const res = envScores.find((score) => score.name === options[i]);
        results.push(res);
      }
    }
    /* Order results */
    const ordered = _.orderBy(results, 'score', 'desc');
    /* Get first 3 results */
    return ordered.splice(0, 3);
  }

  /**
   * Set results to localStorage
   */
  static setResults(results) {
    const resultsString = JSON.stringify(results);
    localStorage.setItem('results', resultsString);
  }
}
