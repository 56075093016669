<template>
  <div class="container-fluid banner">
    <div class="row text-center">
      <div class="banner-text">
        <h2 classs="mb-4">{{ $t('compare.title')}}</h2>
        <p>
          {{ $t('compare.description')}}
        </p>
        <app-button mode="mt-2 mb-2" @clicked="showCompare = !showCompare">
          <template v-if="showCompare">{{
            $t('compare.global_btn')
          }}</template>
          <template v-else>{{ $t('compare.details_btn') }}</template>
        </app-button>
      </div>
    </div>
  </div>
  <div class="container-fluid compare-container">
      <CompareEngines v-if="showCompare" /><GlobalChart v-else />
  </div>
</template>

<script>
import GlobalChart from '@/components/compare/GlobalChart';
import CompareEngines from '@/components/compare/CompareEngines';

export default {
  name: 'Compare',
  components: {
    GlobalChart,
    CompareEngines,
  },
  data() {
    return {
      showCompare: true
    }
  }
};
</script>

<style lang="scss">
.compare-container {
  padding: 3rem 0rem;
}
</style>